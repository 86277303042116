import React from 'react'
import Link from 'gatsby-link'

export default class CaseStudyStep extends React.Component {
  // slidesCheck() makes sure we have slides before rendering
  slidesCheck() { 
    if ( this.props.slideLink !== '#' ) {
      return (
        <Link to={ this.props.slideLink } className="aga-button-regular orange-bg aga-cs-download-button">Download Slides</Link>            
      )
    }
  }

  // Fades content in once the content loads
  componentDidMount() {
    document.getElementById('aga-cs-step').classList.add('aga-fade-in')
  }

  render() {
    return (
      <div>
        <section className="aga-cs-container lightest-gray-bg" id="aga-cs-step">
          <section className="aga-container">
            <h2 className="aga-cs-orange-title">{ this.props.title }</h2>
            <h2 className="aga-cs-question-title">{ this.props.question }</h2>
            { this.props.body }
            { this.slidesCheck() }
          </section>
        </section>
        <section className="orange-bg aga-cs-button-container">
          <Link to={ this.props.backLink } className="aga-button-regular lightest-gray-bg aga-case-study-back-button">« { this.props.backText }</Link>
          <Link to={ this.props.nextLink } className="aga-button-regular lightest-gray-bg aga-case-study-back-button">{ this.props.nextText } »</Link>
        </section>
      </div>
    )
  }
}