import React from 'react'

import Layout from "../../../components/Layout"
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStep from '../../../components/CaseStudyStep'

export default class DiagnosticStepOne extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link active"
            linkTwoClass="aga-casestudy-link"
            linkThreeClass="aga-casestudy-link"
          />

          {/* Case Study Step 1 */}
          <CaseStudyStep
            title="Step #1"
            question="What would your initial diagnosis and treatment plan be for this patient?"
            body={[
              <h3>Expert Opinion</h3>,
              <p>The essential skill of a primary care doctor is to separate out emergency (life-threatening) and urgent (could become life-threatening with delay in management) conditions from chronic conditions less likely to progress or cause significant morbidity or risk of mortality. The following approach should allow a patient like Jennifer to be managed within a primary care setting, expediting an appropriate and value-driven diagnostic and therapeutic plan with referral to a gastrointestinal (GI) specialist without significant delay.</p>,
              <p>Jennifer’s presenting symptoms are relatively common in the population, her initial physical exam and labs are reassuring, and she has not had weight loss or other constitutional signs or symptoms suggesting an urgent or emergency situation. Doing a stool heme-test as soon as possible would be warranted here, however, since a positive result would suggest an inflammatory or neoplastic condition that would warrant more immediate referral to a specialist for luminal examination. Under these conditions, additional blood work be unlikely to yield any additional value.<sup>1</sup></p>,
              <p>If Jennifer’s heme test is negative, however, further steps can be taken at this point within the primary-care setting. Because her symptoms overlap with those from a variety of causes—including irritable bowel syndrome (IBS), lactose intolerance, celiac disease, iron sensitivity, or irritable bowel disease (IBD)—it is impossible to diagnose Jennifer on the basis of her symptoms alone. Instead as a reasonable first step, I would use “time” as a diagnostic and management tool, and work with Jennifer as a partner in a patient-centered approach to develop a plan likely to address the most common reasons for her symptoms.</p>,
              <p>This plan should be as easy as possible for Jennifer to follow. I would most likely begin by asking Jennifer to stop the iron supplementation, given that this is the easiest change for her to make. Many young women need iron supplementation to prevent or treat menstrual-bleeding-related iron-deficiency anemia.  It is important to ask Jennifer is she is adherent to the iron; approximately 50% of patients are non-adherent due to the gastrointestinal side effects.<sup>2</sup> A strategy of temporary iron cessation and observation of impact on GI symptoms is reasonable given her current hemoglobin level.</p>, 
              <p>Given that Jennifer would still need iron, however, reducing the schedule to every-other-day dosing with the addition of 500 mg of vitamin C to enhance absorption could be tried. Iron gluconate and iron fumarate are also available and anecdotally have been described as having fewer GI side effects, although recent randomized trials have failed to provide support. A reduction of symptoms within a short time frame would suggest that iron may be causing or exacerbating her symptoms.</p>,
              <p>If Jennifer does not feel better with manipulation of the iron, I would resume daily iron dosing and move on to the next easiest maneuver for her:  a 2-week course of lactose restriction. This time period should be sufficient to see if she may have lactose intolerance, a condition in which a relative insufficiency of lactase enzyme leaves lactose unbroken down in the gut, resulting in bloating and diarrhea.  If Jennifer feels better after lactose restriction, lactose intolerance is likely, and I would work with her to understand the relative lactose levels of the various dairy foods and offer oral lactase supplementation.</p>,
              <p>If Jennifer continues to have symptoms after lactose restriction, however, celiac disease or non-celiac gluten sensitivity, may be possibilities. Celiac disease is an autoimmune condition where patients develop gut inflammation and symptoms after intake of products containing wheat, barley, and rye.  While frank celiac disease occurs in .5-1% of the general population, gluten sensitivity (an intolerance to gluten with negative autoantibodies performed after a gluten challenge) occurs in up to 15% of the population.<sup>3</sup> </p>,
              <p>To determine if Jennifer has celiac disease, I would retake her dietary history. If she has been eating a fair amount of gluten for the last three months (the equivalent of at least a slice of wheat bread a day), I would consider doing a blood test now for celiac disease. The most cost-effective test is a tTG (anti-tissue transglutamic antibody) test. This test is relatively specific for celiac disease and is a marker of autoimmunity and allergy to gluten.  A common mistake that primary doctors make is restricting gluten-containing foods and then checking blood work later—a situation that may lead to a false negative workup.</p>,
              <p>Patients with low IgA levels may also have a false negative test, so a concurrent IgA level should also be considered.  In addition to the tTG, I would simultaneously test levels of c-reactive protein (CRP) test and/or an ESR (erythrocyte sedimentation rate), biomarkers for inflammation that can complement clinical assessment by distinguishing inflammatory from other conditions without the need for more invasive, time-consuming, and expensive assessment. An elevated CRP or ESR result for Jennifer would suggest an inflammatory condition and therefore heighten my concern for an atypical presentation of IBD as opposed to IBS or other non-inflammatory conditions.  </p>,
              <p>Another test that I might consider as a primary care provider is a calprotectin stool test. Calprotectin is a protein that is released by neutrophils that have caused luminal inflammation. Very elevated levels of calprotectin have been shown to correlate with inflammatory bowel disease. However, the cost of calprotectin testing may be prohibitive to use as a first-line test. In addition, while calprotectin test is a good indicator for colitis and colonic involvement,<sup>4</sup> it is not ideal for detecting ileal disease since it is not specific to IBD and may be increased due to anything that causes gut inflammation, including colorectal carcinoma.</p>,
              <p>For these reasons, a calprotectin stool test should be used judiciously in diagnosing IBD and only after the above simple measures (e.g., diet modification) have been attempted. On the other hand, a negative calprotectin stool test in the absence of other concerning clinical features would be reassuring to both the patient and clinician.<sup>5</sup></p>,

              <h3>Labs</h3>,
              <ul>
                <li>tTG-IgA: 3.7 U/mL</li>
                <li>C-reactive protein (CRP): 3.1 mg/L</li>
                <li>Calprotectin: 99 mcg/g</li>
              </ul>,

              <p><sup>1</sup> Tolkien Z et al. Ferrous sulfate supplementation causes significant gastrointestinal side-effects in adults: A systematic review and meta-analysis. PLoS One. 2015; 10(2): e0117383. Available at: <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4336293/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4336293/</a></p>,
              <p><sup>2</sup> Cancelo-Hidalgo MJ et al. Tolerability of different iron supplements: A systematic review. Curr Med Res Opin 2013 Apr;29(4):291-303. DOI: <a href="https://doi.org/10.1185/03007995.2012.761599">10.1185/03007995.2012.761599</a></p>,
              <p><sup>3</sup> Rubio-Tapia A et al. The prevalence of celiac disease in the United States. Am J Gastroenterol. 2012 Oct;107(10):1538-1544; Catassi C et al. Non-celiac gluten sensitivity: The new frontier of gluten related disorders. Nutrients 2013 Oct; 5(10): 3839–3853. DOI: <a href="https://doi.org/10.1038/ajg.2012.219">10.1038/ajg.2012.219</a></p>,
              <p><sup>4</sup> Cerrillo E et al. Fecal calprotectin in ileal Crohn’s disease: Relationship with magnetic resonance enterography and a pathology score. Inflamm Bowel Dis 2015 Jul;21(6):1572-1579. DOI: <a href="https://doi.org/10.1097/MIB.0000000000000404">10.1097/MIB.0000000000000404</a>; Ye et al. Levels of faecal calprotetin and magnetic resonance enterocolonography correlate with severity of small bowel Crohn’s disease: A retrospective cohort study. Sci Rep 2017 May 16;7(1):1970. DOI: <a href="https://dx.doi.org/10.1038%2Fs41598-017-02111-6">10.1038/s41598-017-02111-6</a></p>,
              <p><sup>5</sup> Kolypov U, Rosenfeld G, Bressler B, Seidman E. Clinical utility of fecal biomarkers for the diagnosis and management of inflammatory bowel disease. Inflamm Bowel Dis. 2014;20: 742–756. DOI: <a href="https://doi.org/10.1097/01.MIB.0000442681.85545.31">10.1097/01.MIB.0000442681.85545.31</a>; Abraham BP, Sellin JH. Fecal calprotectin: controlling the cost of care. Clin Gastroenterol Hepatol2014 Feb;12(2):263-264. DOI: <a href="https://doi.org/10.1016/j.cgh.2013.09.018">10.1016/j.cgh.2013.09.018</a>; Walsham NE, Sherwood RA. Fecal calprotectin in inflammatory bowel disease. Clin Exp Gastroenterol 2016; 9: 21–29. DOI: <a href="https://dx.doi.org/10.2147%2FCEG.S51902">10.2147/CEG.S51902</a></p>
            ]} 
            slideLink="#"
            cmeLink="/cme"
            backLink="/cases/primary-care-diagnosis#part-1"
            backText="Back to Case One"
            nextLink="/steps/step-2"
            nextText="Go to Step 2"
          />
        </Layout>
      </div>
    )
  }
}