import React from 'react'
import Link from 'gatsby-link'

export default class CaseStudyNav extends React.Component {
  render() {
    return (
      <div>
        <nav className="aga-casestudy-nav">
          <Link 
            to="/cases/primary-care-diagnosis" 
            className={ this.props.linkOneClass }
          >
            Case 1
          </Link>
          <Link 
            to="/cases/medication-management-gastroenterology" 
            className={ this.props.linkTwoClass }
          >
            Case 2
          </Link>
          <Link 
            to="/cases/health-maintenance-primary-care" 
            className={ this.props.linkThreeClass }
          >
            Case 3
          </Link>
        </nav>
      </div>
    )
  }
}
